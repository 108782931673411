import React from "react";
import {useQuery} from "@apollo/client";
import {window} from "window-or-global";

import Main from "../components/main";
import Seo from "../components/section/seo";
import Loader from "../components/bootstrap/loader";
import MfaComponent from "../components/mfa";
import {getCurrentUserQuery} from "../components/logic/user";

export default function Mfa() {
  return (
    <Main>
      <Seo title="Evergy - Portal" />
      <MfaIndex/>
    </Main>
  );
}


const MfaIndex = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (!user) {
    window.location.href = "/login";
  }

  return <MfaComponent user={user} userQueryRefetch={currentUserQuery?.refetch}/>;
};
